import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBnZ11xJPIHGqwVsCgEIFDr6GQZjKMBb8c',
  authDomain: 'codex-generaciones-futuro.firebaseapp.com',
  projectId: 'codex-generaciones-futuro',
  storageBucket: 'codex-generaciones-futuro.appspot.com',
  messagingSenderId: '279962260913',
  appId: '1:279962260913:web:441dc4d436c3a72ec71990',
  measurementId: 'G-24J3HJV730',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
